import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import yosaLogo from '../images/yosalogo.png';
import signUpPic from '../images/signup.png';
import '../styles/SignUp.css';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'us-east-1_2Pbc3zfmr', 
    ClientId: '7q641c75ljpv2snpitel88muip', 
};

const userPool = new CognitoUserPool(poolData);

const ConfirmSignup = () => {
    const [confirmationCode, setConfirmationCode] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email;
    const [errorMessage, setErrorMessage] = useState("");

    const onConfirmSignUp = (email, confirmationCode) => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
            if (err) {
                console.error('Confirmation Error:', err);
                setErrorMessage(err.message || 'An error occurred during confirmation.');
                return;
            }
            console.log('Confirmation Result:', result);
            navigate('/login');
        });
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        onConfirmSignUp(email, values.confirmationCode);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <section className='signup-container'>
                <div className='signup-left'>
                    <img id='signupimage' src={signUpPic} alt='Yosa Lady' />
                </div>
                <div className='signup-right'>
                    <img className='signuplogo' src={yosaLogo} alt="Yosa Logo" />
                    <Form
                        name="basic"
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                        >
                            <Input value={email} disabled />
                        </Form.Item>

                        <Form.Item
                            label="Confirmation Code"
                            name="confirmationCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your confirmation code!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {errorMessage && (
                            <div className='error-message'>
                                <p style={{ color: 'red' }}>{errorMessage}</p>
                            </div>
                        )}

                        <div className='center'>
                            <Button className='forgot-password' onClick={() => navigate('/login')}>Already have an account? Log in here</Button>
                            <Form.Item>
                                <Button className='submit-button' type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </section>
        </>
    );
};

export default ConfirmSignup;
