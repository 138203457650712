// src/components/providerdashboard/InviteModal.js
import React, { useState } from 'react';

const InviteModal = ({ onSubmit, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, email });
    onClose();
  };

  return (
    <div className="modal">
      <h2>New Client Invite</h2>
      <form onSubmit={handleSubmit}>
        <label>Name:</label>
        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
        <label>Email:</label>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <button type="submit">Send Invite</button>
      </form>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default InviteModal;