import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import yosaLogo from '../images/yosalogo.png';
import signUpPic from '../images/signup.png';
import '../styles/SignUp.css';
import axios from 'axios';
import UserPool from "../UserPool";
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    const fetchCodes = async () => {
        try {
            const response = await axios.get('https://admin.yosaapp.com/api/Codes/', {
  
            });
    
            // const codes = response.data.map(codeObj => codeObj.code);
            return response.data;
        } catch (error) {
            console.error('Error fetching codes:', error);
            setErrorMessage('Error fetching codes.');
            return [];
        }
    };

    const deleteCode = async (code) => {
        try {

            const codes = await fetchCodes();
            console.log(codes);
            
            const codeObj = codes.find(codeObj => codeObj.code === code);
            console.log(codeObj);
            if (codeObj) {
                // deleting the code by its id
                const response = await axios.delete(`https://admin.yosaapp.com/api/Codes/${codeObj.id}/`, {
                });
                console.log('Code deletion response:', response.data);
            } else {
                console.error('Code not found.');
            }
        } catch (error) {
            console.error('Error deleting code:', error);
        }
    };
    

    const onSubmit = async (email, password, code) => {
        const validCodes = await fetchCodes();
        const validCodes2 = validCodes.map(codeObj => codeObj.code);
        console.log(validCodes2);
        console.log('code:',code);

        if (validCodes2.includes(code)) {
            UserPool.signUp(email, password, [], null, (err, data) => {
                if (err) {
                    console.error(err);
                    setErrorMessage(err.message || 'An error occurred during sign up.');
                } else {
                    console.log(data);
                    deleteCode(code);
                    navigate('/confirm-sign-up', { state: { email: email } }); // push to confirmation view
                }
            });
        } else {
            console.error('Invalid code, if this error persists, please contact support.');
            setErrorMessage('Invalid code, if this error persists, please contact support.');
        }
    };

    const goToLogIn = () => {
        navigate('/login');
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        setEmail(values.email);
        setPassword(values.password);
        onSubmit(values.email, values.password, values.code);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <section className='signup-container'>
                <div className='signup-left'>
                    <img id='signupimage' src={signUpPic} alt='Yosa Lady' />
                </div>
                <div className='signup-right'>
                    <img className='signuplogo' src={yosaLogo} alt="Yosa Logo" />
                    <Form
                        name="basic"
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Code"
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the code provided to you!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="confpassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                            
                        </Form.Item>
                        {errorMessage && (
    <div className='error-message' style={{ color: 'red' }}>
        <p>{errorMessage}</p>
    </div>
)}
                        <div className='center'>
                            <Button className='forgot-password' onClick={goToLogIn}>Already have an account? Log in here</Button>
                            <Form.Item>
                                <Button className='submit-button' type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </section>
        </>
    );
};

export default Signup;
