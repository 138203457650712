// src/components/providerdashboard/DashboardApp.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import WelcomeBanner from './WelcomeBanner';
import WorksheetsPanel from './WorksheetsPanel';
import ClientsPanel from './ClientsPanel';
import InviteModal from './InviteModal';
import WorksheetUploadModal from './WorksheetUploadModal';

function DashboardApp() {
  const [clients, setClients] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [expandedClient, setExpandedClient] = useState(null);

  const handleSendInvite = (client) => {
    setClients([...clients, { ...client, status: 'Sent', worksheets: [] }]);
  };

  const handleUploadWorksheet = (worksheet) => {
    setWorksheets([...worksheets, worksheet]);
  };

  const handleEditClient = (id, updatedClient) => {
    setClients(clients.map(client => client.id === id ? updatedClient : client));
  };

  const handleAssignWorksheet = (clientId, worksheet) => {
    setClients(clients.map(client => {
      if (client.id === clientId) {
        return { ...client, worksheets: [...client.worksheets, worksheet] };
      }
      return client;
    }));
  };

  const handleDeleteProfile = (clientId) => {
    setClients(clients.filter(client => client.id !== clientId));
  };

  const handleDeleteWorksheet = (worksheetId) => {
    setWorksheets(worksheets.filter(worksheet => worksheet.id !== worksheetId));
  };

  const toggleClientDetails = (id) => {
    setExpandedClient(expandedClient === id ? null : id);
  };

  const toggleInviteModal = () => {
    setShowInviteModal(!showInviteModal);
  };

  const toggleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  return (
    <div className="app">
      <Sidebar />
      <main>
        <WelcomeBanner />
        <WorksheetsPanel worksheets={worksheets} onDeleteWorksheet={handleDeleteWorksheet} />
        <ClientsPanel 
          clients={clients} 
          onEditClient={handleEditClient} 
          onDeleteClient={handleDeleteProfile}
          onAssignWorksheet={handleAssignWorksheet}
          onToggleClientDetails={toggleClientDetails}
          expandedClient={expandedClient}
        />
        {showInviteModal && <InviteModal onSubmit={handleSendInvite} onClose={toggleInviteModal} />}
        {showUploadModal && <WorksheetUploadModal onSubmit={handleUploadWorksheet} onClose={toggleUploadModal} />}
      </main>
    </div>
  );
}

export default DashboardApp;