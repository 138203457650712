import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { UploadOutlined, LogoutOutlined, UserAddOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Space, Table, Button, Tooltip, Layout, Menu, theme, message, Modal, Form, Input, Upload, Spin, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import balancingguy from '../images/balancingguy.png';
import greentguy from '../images/greentguy.png';
import yellowshirtlady from '../images/yellowshirtlady.png';
import '../styles/Dashboard.css'; // Import the CSS file for custom styling
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { Amplify } from 'aws-amplify';
//import { Storage } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
//import awsconfig from '../aws-exports';
import { getCurrentUser } from 'aws-amplify/auth';
import axios from 'axios';
import { signOut } from 'aws-amplify/auth';
import { pdf } from '@react-pdf/renderer';
import { deleteUser } from 'aws-amplify/auth';


const client = generateClient();

const poolData = {
  UserPoolId: 'us-east-1_2Pbc3zfmr', 
  ClientId: '7q641c75ljpv2snpitel88muip', 
};

const InputComponent = forwardRef(({ label, required, value, onChange, placeholder }, ref) => {
  const inputRef = useRef(null);

  const getValue = useCallback(() => inputRef.current.value, []);

  const setValue = useCallback((value) => {
    inputRef.current.value = value;
  }, []);

  const clearValue = useCallback(() => {
    inputRef.current.value = "";
  }, []);

  useImperativeHandle(ref, () => ({ getValue, setValue, clearValue }), []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
      <label style={{ marginBottom: '10px', marginRight: '10px', paddingTop: '10px', paddingBottom: '0px' }}>
        {required && <span style={{ color: 'red' }}>*</span>} {label}
      </label>
      <input
        ref={inputRef}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          padding: '3px 3px 3px 6px',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
          width: '100%',
          fontSize: '14px'
        }}
      />
    </div>
  );
});

const userPool = new CognitoUserPool(poolData);

const authConfig = {
  Cognito: {
    userPoolId: 'us-east-1_2Pbc3zfmr',
    userPoolClientId: '7q641c75ljpv2snpitel88muip'
  }
};

Amplify.configure({
  Auth: authConfig
});




const getCurrentUsername = async () => {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    //console.log(`The username: ${username}`);
    //console.log(`The userId: ${userId}`);
    //console.log(`The signInDetails: ${signInDetails}`);
    return username;
  } catch (err) {
    console.error('Error getting current user:', err);
    throw err;
  }
};

const Dashboard = () => {
  const [clients, setClients] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [clientWorksheets, setClientWorksheets] = useState([]);
  const [isWorksheetModalVisible, setIsWorksheetModalVisible] = useState(false);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const emailInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const [newWorksheet, setNewWorksheet] = useState({ name: '', file: null });
  const [newClient, setNewClient] = useState({ name: '', email: '' });
  const [loading, setLoading] = useState(true); // Add loading state
  const [selectedClientKey, setSelectedClientKey] = useState(null);
  const [selectedWorksheets, setSelectedWorksheets] = useState([]);
  //const [selectedWorksheetKey, setSelectedWorksheetKey] = useState([]);
  const [editableClientKey, setEditableClientKey] = useState(null);
  const [selectedOtherAssignments, setSelectedOtherAssignments] = useState([]);
  const [selectedClientEmail, setSelectedClientEmail] = useState(null);
  const [editableClientName, setEditableClientName] = useState('');
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [therapySheets, setTherapySheets] = useState([]);
  const [modalContent, setModalContent] = useState('');

  

  const navigate = useNavigate();
  const goToHome = () => {
    const user = userPool.getCurrentUser();

    if (user) {
        //user.signOut();
        //console.log('user is user')
        //navigate('/');
    } else {
        //console.log('No user is currently logged in.');
        navigate('/');
    }
};




  useEffect(() => {
    // Simulate a delay to fetch data (replace with actual fetch calls)
    setTimeout(() => {
      setLoading(false); // Set loading to false after data is fetched
    }, 1000); // Adjust the delay as needed
  }, []);

  const handleMenuClick = (e) => {
    if (e.key === '3') {
      goToHome();
    } else if (e.key === '2') {
      setIsWorksheetModalVisible(true);
    } else if (e.key === '1') {
      setIsInviteModalVisible(true);
    }
  };

  const generateItemsTable = (clientId, clientEmail) => [
    {
      key: `sendAssignment-${clientId}`,
      label: 'Send Assignment',
      onClick: () => handleAssignWorksheet(clientId, clientEmail),
    },
    {
      key: `deleteProfile-${clientId}`,
      label: 'Delete Profile',
      onClick: () => showDeleteConfirmModal(clientId, clientEmail),
    },
  ];
  

  

  const handleAssignWorksheet = (clientId, clientEmail) => {
    setSelectedClientKey(clientId);
    setSelectedClientEmail(clientEmail);
    setIsAssignModalVisible(true);
  };
  
  const [filename, setFilename] = useState('')
  const [files, setFiles] = useState([{}])
  const [status, setstatus] = useState('')
  let api = 'https://admin.yosaapp.com/api'

  const postWorkSheet = async (username, name, pdfFile) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('name', name);
    formData.append('file', pdfFile);


    let axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Add the Authorization header
        }
    };

    const newWorksheet = {
      id: Date.now(), // Temporary ID, replace with actual ID from server
      username,
      name,
      file: pdfFile,
      status: 'Incomplete',
      response: 'Client has not completed the worksheet yet.'
    };
  
    setWorksheets(prevWorksheets => [...prevWorksheets, newWorksheet]);

    //console.log("Form data: ", formData);
    axios.post(api + '/WorkSheets/', formData, axiosConfig).then(
        response => {
            //console.log(response);
            //setStatus('File Uploaded Successfully');
            setRefresh(prev => !prev);
        }
    ).catch(error => {
        //console.log(error);
    });
    //setRefresh(prev => !prev);
};


const postWorksheetResponse = async (title, username) => {
  const formData = new FormData();
  //const username = await fetchUsernameByEmail(email);
  formData.append('username', username);
  formData.append('title', title);
  formData.append('status', 'Incomplete');
  formData.append('response', 'Client has not completed the worksheet yet.');

  let axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  //console.log("Form data: ", formData);
  axios.post(api + '/WorksheetResponse/', formData, axiosConfig).then(
    response => {
        //console.log(response);
        //console.log('Response Uploaded Successfully');
        setRefresh(prev => !prev);
    }
  ).catch(error => {
    //console.log(error);
  });
};

const postTherapySheet = async (username, name, file, worksheetId) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('name', name);
  formData.append('file', file);
  formData.append('status', "Incomplete");
  formData.append('response', "");
  formData.append('worksheetId', worksheetId);
  
  const optimisticTherapySheet = {
    id: worksheetId, // Assuming worksheetId is unique
    username,
    name,
    file,
    response: "Incomplete.",
    status: "Incomplete" // This is the initial status before server confirmation
  };

  // Assuming therapySheets is the state variable holding the therapy sheets
  setTherapySheets(prevSheets => [...prevSheets, optimisticTherapySheet]);

  let axiosConfig = {
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Add the Authorization header
      }
  };

  //console.log("Form data: ", formData);
  axios.post(api + '/TherapySheets/', formData, axiosConfig).then(
      response => {
          //console.log(response);
          setstatus('File Uploaded Successfully');
          //setRefresh(prev => !prev);
      }
  ).catch(error => {
      //console.log('Error posting therapy sheet:', error.response || error.message || error);
  });
};



const postNotification = async (username, type, worksheetId, name) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('name', name);
  formData.append('viewed', false);
  formData.append('type', type);
  formData.append('worksheetId', worksheetId);
  
  //console.log(type);

  // Use the admin username and password


  let axiosConfig = {
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Add the Authorization header
      }
  };

  //console.log("Form data: ", formData);
  axios.post(api + '/Notifications/', formData, axiosConfig).then(
      response => {
          //console.log(response);
          //setstatus('Notification Posted Successfully');
          //setRefresh(prev => !prev);
      }
  ).catch(error => {
      if (error.response) {
          console.error('Error response:', error.response.data);
      } else if (error.request) {
          console.error('Error request:', error.request);
      } else {
          console.error('Error message:', error.message);
      }
  });
};



const roundDateToNearestFiveMinutes = (date) => {
  const ms = 1000; // 5 minutes in milliseconds
  return new Date(Math.round(new Date(date).getTime() / ms) * ms);
};


const deleteNotification = async (username, name, worksheetId) => {
  try {
      // Use the admin username and password


      // Step 1: Fetch all notifications
      const response = await axios.get(api + '/Notifications/', {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Add the Authorization header
          }
      });
      const notifications = response.data;

      //console.log("KJDFKDS", name, username, worksheetId);

      // Step 3: Filter notifications to find the one matching rounded date and username
      const notificationToDelete = notifications.find(notification => {
          return notification.name == name && notification.worksheetId === worksheetId;
      });

      if (!notificationToDelete) {
          //console.log('Notification not found');
          return;
      }

      // Step 4: Delete the notification using its ID
      await axios.delete(api + `/Notifications/${notificationToDelete.id}/`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
          }
      });

      //console.log('Notification deleted successfully');
      
      //setRefresh(prev => !prev);
  } catch (error) {
      //console.log('Error deleting notification:', error);
  }
};

const findAndDeleteTherapySheet = async (url, name, clients) => {
  try {
    //console.log('Clients:', clients);
    for (const client of clients) {
      //console.log('Client:', client.email);
      // Load worksheets for the current client
      await LoadClientWorksheets(client.email);
      //console.log('undefinde?',clientWorksheets);

      // Filter out the sheets with the specified URL and name
      const matchingSheets = clientWorksheets.filter(sheet => sheet.file === url && sheet.name === name);
      //console.log('Matching sheets:', matchingSheets);

      if (matchingSheets.length === 0) {
        //console.log('No matching therapy sheets found for', client.email);
        continue;
      }

      // Loop through each matching sheet and call deleteTherapySheet
      for (const sheet of matchingSheets) {
        const therapySheetId = sheet.id; // Assuming each sheet has a unique ID
        await deleteTherapySheet(client.email, therapySheetId, name, sheet.worksheetId);
      }

      // Update state to remove the deleted sheets
      setClientWorksheets(prevSheets => prevSheets.filter(sheet => !(sheet.file === url && sheet.name === name)));
    }
  } catch (error) {
    //console.error('Error deleting therapy sheet:', error);
  }
};



const deleteTherapySheet = async (email, therapySheetId, name, worksheetId) => {
  try {
      setClientWorksheets(prevSheets => prevSheets.filter(sheet => sheet.id !== therapySheetId));
      const username = await fetchUsernameByEmail(email);
      //deleteNotification(date, username);
      const url = `${api}/TherapySheets/${therapySheetId}/`;

      // Encode the username and password for basic authentication


      // Make the DELETE request
      const response = await axios.delete(url, {
          headers: {
             'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
          }
      });

      if (response.status === 204) {
          //console.log('Therapy sheet deleted successfully');
          deleteNotification(username, name, worksheetId);

          if (response.status === 204) {
              //console.log('Notification sheet delete called successfully');
              // Remove the deleted sheet from the state
              
          }
      } else {
          throw new Error('Failed to delete therapy sheet');
      }
  } catch (error) {
      console.error('Error deleting therapy sheet:', error);
  }
};



const generateWorksheetId = () => {
  const timestamp = Date.now(); // Current timestamp in milliseconds
  const randomNum = Math.floor(Math.random() * 1000000); // Random number between 0 and 999999
  return timestamp.toString() + randomNum.toString(); // Concatenate timestamp and random number
};

const handlePostTherapySheet = async (clientKey) => {
  

  const worksheetId = generateWorksheetId();



  // Ensure there is a selected worksheet
  if (selectedWorksheets.length === 0) {
    message.error('Please select a worksheet.');
    return;
  }

  // Find the selected worksheet
  const selectedWorksheetKey = selectedWorksheets[0]; // Assuming only one can be selected
  const selectedWorksheet = worksheets.find(w => w.id === selectedWorksheetKey);

  if (!selectedWorksheet) {
    message.error('Selected worksheet not found or client is not yet registered.');
    //console.log(selectedWorksheetKey);
    return;
  }

  const { name, file } = selectedWorksheet;
  //console.log('clientkey:', clientKey);
  const client = clients.find(client => client.id === client.id);
  //console.log('this is the client',client);
  const clientEmail = clients.find(client => client.id === clientKey).email;
  //console.log(clientEmail);
  //console.log('name:', name, 'file:', file);
  //console.log(worksheets);
  //console.log('id:', selectedWorksheet.id);

  if (!name || !file) {
    //console.log(name, file);
    message.error('Please enter a name and select a file.');
    return;
  }

  try {
    const username = await fetchUsernameByEmail(clientEmail); // Fetch username using client's email
    if (username) {
      await postTherapySheet(username, name, file, worksheetId);
      await postNotification(username, 'worksheet', worksheetId, name);

      setSelectedWorksheets([]);
      // Reset the form and close the modal
      setNewWorksheet({ name : '', file: null });
      LoadClientWorksheets(clientEmail);
      setIsAssignModalVisible(false);
    } else {
      message.error('User is not registered. Ensure that the client has registered with the same email you provided. If this issue persists, please contact support@yosaapp.com.');
    }
  } catch (error) {
    console.error('Error fetching username:', error);
    message.error('Error fetching username.');
  }
};

async function handleDeleteAccount() {
  try {
    await deleteUser();
    navigate('/');
  } catch (error) {
    //console.log(error);
  }
}


const getWorksheets = () =>{

  axios.get(api + '/WorkSheets/').then(
      response =>{
          //console.log(response.data)
          setWorksheets(response.data)
      }
  ).catch(error =>{
      //console.log(error)
  })

}


const fetchUsernameByEmail = async (email) => {
  try {
    // Encode the username and password for basic authentication


    const response = await fetch(`https://admin.yosaapp.com/api/Users/getUsernameByEmail/?email=${email}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
       'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Add the Authorization header
      },
    });

    if (!response.ok) {
      const errorText = await response.text();  // Get the error message from the response
      throw new Error(`Failed to fetch username: ${response.status} ${response.statusText} - ${errorText}`);
    }

    const data = await response.json();
    return data.username;  // Adjust based on actual response structure
  } catch (error) {
    console.error('Error fetching username:', error.message || error);
    return null;
  }
};





const handlePostWorkSheet = async () => {
  //console.log('Posting worksheet...');
  const name = nameInputRef.current.getValue();
  const { file } = newWorksheet;
  //console.log(name, file);
  if (!name || !file) {
    //console.log(name,file);
    message.error('Please enter or reenter a name and select a file.');
    return;
  }

  const username = await getCurrentUsername();
  if (username) {
    await postWorkSheet(username, name, file);
    setSelectedWorksheets(prevWorksheets => [...prevWorksheets, newWorksheet]);
    
    nameInputRef.current.clearValue();
    setNewWorksheet({ name: '', file: null });
    setIsWorksheetModalVisible(false);
    
  }
};

const deleteTherapySheets = async (email) => {
  try {
    // Fetch the username associated with the provided email
    const username = await fetchUsernameByEmail(email);
    if (!username) {
      throw new Error('Username could not be fetched.');
    }

    // Fetch all therapy sheets associated with the username
    const therapySheetsResponse = await axios.get(`${api}/TherapySheets/`, {
      params: { username: username },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Replace with actual auth
      },
    });

    const therapySheets = therapySheetsResponse.data;

    // Iterate through each therapy sheet and delete it
    for (const sheet of therapySheets) {
      const url = `${api}/TherapySheets/${sheet.id}/`;

      const deleteResponse = await axios.delete(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Replace with actual auth
        },
      });

      if (deleteResponse.status === 204) {
        //console.log(`Therapy sheet with ID ${sheet.id} deleted successfully`);
        await deleteNotification(username, sheet.name, sheet.worksheetId); // Ensure this is awaited if it returns a promise
        //console.log('Notification deleted:', sheet.name, username, sheet.worksheetId);
      } else {
        console.error(`Failed to delete therapy sheet with ID ${sheet.id}: ${deleteResponse.statusText}`);
      }
    }

    // Remove the deleted sheets from the state
    setTherapySheets(prevSheets => prevSheets.filter(sheet => sheet.username !== username));

  } catch (error) {
    console.error('Error deleting therapy sheets:', error.message || error);
  }
};


const handleDeleteClient = (clientId, clientEmail) => {
  //console.log('clients ',clientId)
    deleteClient(clientId);
    deleteTherapySheets(clientEmail);
};

const handleAssignOk = () => {
  setClients(clients.map(client => {
    if (client.key === selectedClientKey) {
      return {
        ...client,
        worksheets: [
          ...selectedWorksheets.map(key => {
            const worksheet = worksheets.find(w => w.key === key);
            return { ...worksheet, date: new Date().toLocaleDateString() };
          }),
          ...selectedOtherAssignments.map(item => ({
            key: item,
            name: item,
            date: new Date().toLocaleDateString(),
          }))
        ]
      };
    }
    return client;
  }));
  setIsAssignModalVisible(false);
  setSelectedWorksheets([]);
  setSelectedOtherAssignments([]);
};

const [isModalVisible, setIsModalVisible] = useState(false);

const showModal = () => {
  setIsModalVisible(true);
};

const handleOk = () => {
  setIsModalVisible(false);
};

const handleCancel1 = () => {
  setIsModalVisible(false);
};



const showResponseModal = (response) => {
  setModalContent(response === "" 
    ? "Your client has not yet submitted a response. If you think this is an error on our end, please contact support@yosaapp.com. Thank you!" 
    : response);
  setIsModalVisible(true);
};  

const expandedRowRender = (record) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => {
        const date = new Date(text);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (registrationStatus) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: '5px',
              backgroundColor: registrationStatus === 'Complete' ? 'green' : 'red',
            }}
          />
          <span style={{ color: 'black', fontWeight: 'normal' }}>
            {registrationStatus}
          </span>
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      align: 'left',
      render: (_, record) => (
        <Space size="middle" style={{ marginLeft: 'auto' }}>
          <Button 
            type="link"
            onClick={() => openPdf(record.file)} 
            style={{ 
              color: '#735A03', 
              textDecoration: 'none',
              padding: '0',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '16px' 
            }}
          >
            View Worksheet
          </Button>
          <Button 
            type="link"
            onClick={() => showResponseModal(record.response)} 
            style={{ 
              color: '#735A03', 
              textDecoration: 'none',
              padding: '0',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '16px' 
            }}
          >
            View Response
          </Button>
          <a onClick={() => deleteTherapySheet(record.email, record.id, record.name, record.worksheetId)} 
            style={{ 
              color: '#735A03', 
              textDecoration: 'none',
              cursor: 'pointer',
              fontSize: '16px' 
            }}
          >
            Unsend
          </a>
          <Modal 
        title="Your Client's Response:" 
        visible={isModalVisible} 
        onOk={handleOk}
        onCancel={handleCancel1}
        maskStyle={{ 
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly dark background
          backdropFilter: 'blur(0px)' // Apply blur effect
        }}
      >
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {modalContent}
        </pre>
      </Modal>
        </Space>
      ),
    },
  ];
  return <Table columns={columns} dataSource={clientWorksheets} pagination={false} />;
};

const openAssignModal = (clientEmail) => {
  setSelectedClientEmail(clientEmail);
  setIsAssignModalVisible(true);
};


const LoadWorksheets = async () => {
  setLoading(true);  // Assuming you have a loading state to set

  try {
      // Get the current authenticated user
      const username = await getCurrentUsername();

      // Build the URL with the query parameter
      const url = new URL('https://admin.yosaapp.com/api/WorkSheets/');
      url.searchParams.append('username', username);

      // Encode the username and password for basic authentication

      // Set up the request
      const request = new Request(url, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Set the Authorization header
          },
      });

      // Fetch the data
      const response = await fetch(request);
      if (!response.ok) {
          throw new Error('Failed to fetch worksheets');
      }

      // Parse the JSON response
      const data = await response.json();
      setWorksheets(data);  // Assuming you have a state setter for worksheets
  } catch (error) {
      console.error('Error loading worksheets:', error);
  } finally {
      setLoading(false);
  }
};


const LoadClientWorksheets = async (email) => {
  setLoading(true);  // Assuming you have a loading state to set
  const username = await fetchUsernameByEmail(email);

  try {
      // Build the URL with the query parameter
      const url = new URL('https://admin.yosaapp.com/api/TherapySheets/');
      url.searchParams.append('username', username);

      // Set up the request
      const request = new Request(url, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Set the Authorization header
          },
      });

      // Fetch the data
      const response = await fetch(request);
      if (!response.ok) {
          throw new Error('Failed to fetch therapy sheets');
      }

      // Parse the JSON response
      const data = await response.json();
      setClientWorksheets(data);  // Assuming you have a state setter for worksheets
      //console.log('Client worksheets:', data); // Log the data correctly
  } catch (error) {
      //console.error('Error loading therapy sheets:', error);
  } finally {
      setLoading(false);
  }
};



  const handleDeleteWorksheet = (clientKey, worksheetKey) => {
    //console.log('afbikuaswhfjswadkfwb');
    setClients(clients.map(client => {
      if (client.key === clientKey) {
        return {
          ...client,
          worksheets: client.worksheets.filter(w => w.key !== worksheetKey)
        };
      }
      return client;
    }));
  };




  const handleEdit = (key) => {
    setEditableClientKey(key);
    const client = clients.find(client => client.key === key);
    setEditableClientName(client.name);
  };

  const saveEdit = (key) => {
    const client = clients.find(client => client.key === key);
    if (client) {
      updateClient(client.id, { name: editableClientName, email: client.email });
    }
    setEditableClientKey(null);
    setEditableClientName('');
  };

const overturnRegistrationStatus = async (client) => {
  try {
  const response = await fetch(`https://admin.yosaapp.com/api/Clients/${client.id}/`, {
      method: 'PUT',
      headers: {
          'Accept': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Replace with your actual auth
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          ...client,
          isRegistered: false,
          registrationStatus: 'Unregistered',
      }),
  });

  if (!response.ok) {
      throw new Error('Failed to update client registration status');
  }
  //console.log(`Client ${client.email} registration status updated to true`);

} catch (error) {
  console.error('Error updating client registration status:', error);
  throw error;
}
};

const changeRegistrationStatus = async (client) => {
  try {
    // Encode admin credentials in Base64


    const response = await fetch(`https://admin.yosaapp.com/api/Clients/${client.id}/`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Use the encoded credentials
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...client,
        isRegistered: true,
        registrationStatus: 'Registered',
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update client registration status');
    }

    //console.log(`Client ${client.email} registration status updated to true`);
    return response.json();  // Return the result of the fetch operation
  } catch (error) {
    console.error('Error updating client registration status:', error);
    throw error;
  }
};


const checkRegistrationStatus = async () => {
  setLoading(true);  // Set loading to true before fetching data
  //console.log('cakked');
  const promises = clients.map(async (client) => {
      //console.log('hisfasie',client);
      const username = await fetchUsernameByEmail(client.email);
      if (username && !client.isRegistered) {
          //console.log('Client is not registered')
          await LoadClients();
          //setRefresh(prev => !prev);
          setLoading(false);  
          return changeRegistrationStatus(client);
      }
      if (!username && client.isRegistered) {
          //console.log('Client unregistered');
          await LoadClients();
          //setRefresh(prev => !prev);
          setLoading(false);  
          return overturnRegistrationStatus(client);
      }
  });
  setLoading(false); 
  return Promise.all(promises);
};

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <span>{text}</span>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'registrationStatus',
      key: 'registrationStatus',
      render: (registrationStatus) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: '5px',
              backgroundColor: registrationStatus === 'Registered' ? 'green' : 'red',
            }}
          />
          <span style={{ color: 'black', fontWeight: 'normal' }}>
            {registrationStatus}
          </span>
          <div style={{ flexGrow: .3 }} /> {/* Spacer */}
          <Button 
            type="link"
            onClick={() => checkRegistrationStatus()} 
            style={{ 
              color: '#AC8200', 
              textDecoration: 'none',
              padding: '0',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '16px'
            }}
          >
            Check Registration Status
          </Button>
          
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => (
        <Space size="small">
          <Dropdown overlay={<Menu items={generateItemsTable(record.id, record.email)} />}>
            <a onClick={(e) => e.preventDefault()}>
              Send Assignment/Delete Client <DownOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const columnsWorksheet = worksheets.length > 0 ? [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
        <Button 
          type="link"
          onClick={() => openPdf(record.file)} 
          style={{ 
            color: '#FFEBA3', // Light yellow color
            textDecoration: 'none',
            padding: '0',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            fontSize: '16px' // Adjust this value to match the font size of the "Send Assignment" link
          }}
        >
          View
        </Button>
        <a onClick={() => handleDeleteWorksheet1(record)}>Delete</a>
      </Space>
      ),
    },
  ] : [];  

  const handleDeleteWorksheet1 = (worksheet) => {
    setWorksheetToDelete(worksheet);
    setIsDeleteWorksheetModalVisible(true);
  };

  const deleteWorksheet = async (worksheetId, name, url, email) => {
    setIsDeleteModalVisible(false);
    setWorksheetToDelete(null);
    try {
      const previousWorksheets = [...worksheets];
      setWorksheets(prevWorksheets => prevWorksheets.filter(ws => ws.id !== worksheetId));
  
      const response = await fetch(`https://admin.yosaapp.com/api/WorkSheets/${worksheetId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Use the encoded authorization header
          'Content-Type': 'application/json'  // Specify the content type
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to delete worksheet: ${response.statusText}`);
      }
  
      console.log('Worksheet deleted successfully');
      // Call findAndDeleteTherapySheet after deleting the worksheet
      await findAndDeleteTherapySheet(url, name, clients);
    } catch (error) {
      console.error('Error deleting worksheet:', error);
    }
  };
  
  

  const handleWorksheetOk = async (key) => {
    if (newWorksheet.name && newWorksheet.file) {
      setWorksheets(prevWorksheets => [
        ...prevWorksheets,
        { key: prevWorksheets.length.toString(), name: newWorksheet.name, file: newWorksheet.file },
      ]);
      setNewWorksheet({ name : '', file: null });
      setIsWorksheetModalVisible(false);
    } else {
      message.error('Please enter a valid name and select a file.');
    }
  };



  const updateClient = async (clientId, clientData) => {
    try {
      const response = await fetch(`https://admin.yosaapp.com/api/Clients/${clientId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Replace with your actual auth
        },
        body: JSON.stringify(clientData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update client');
      }
  
      const data = await response.json();
      setClients(prevClients => prevClients.map(client => client.id === clientId ? data : client));
      //console.log('Client updated successfully', data);
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };
  
  // Function to delete a client
  const deleteClient = async (clientId) => {
    try {
      //console.log("client ididid",clientId);
      const response = await fetch(`https://admin.yosaapp.com/api/Clients/${clientId}/`, {
        
        method: 'DELETE',
        headers: {
         'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete client');
      }
  
      setClients(prevClients => prevClients.filter(client => client.id !== clientId));
      //console.log('Client deleted successfully');
      //setRefresh(prev => !prev);
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleInviteOk = async () => {
    if (nameInputRef.current.getValue() && emailInputRef.current.getValue()) {
      /*setClients(prevClients => [
        ...prevClients,
        { key: prevClients.length.toString(), name: newClient.name, email: newClient.email, status: 'Not Registered', worksheets: [] },
      ]);*/
  
      try {
        await postClient(nameInputRef.current.getValue(), emailInputRef.current.getValue());
        nameInputRef.current.clearValue();
        emailInputRef.current.clearValue();
        setIsInviteModalVisible(false);
      } catch (error) {
        message.error('Failed to post client.');
      }
    } else {
      message.error('Please enter a name and email.');
    }
  };
  


  const postClient = async (name, email) => {
    const formData = new FormData();
  
    try {
      // Get the current authenticated username
      const username = await getCurrentUsername();
  
      formData.append('username', username);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('isRegistered', false);
      formData.append('registrationStatus', 'Not Registered');
  
      // Encode the admin credentials for basic authentication
      //const accessToken = localStorage.getItem('access_token');
  
      const response = await fetch('https://admin.yosaapp.com/api/Clients/', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Use the encoded authorization header
          // 'Content-Type': 'multipart/form-data' is not needed with FormData
        },
        
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error(`Failed to post client: ${response.statusText}`);
        
      }
  
      //console.log('Client posted successfully');
  
      // Reload clients and check registration status
      await LoadClients();
      await checkRegistrationStatus();
      setRefresh(prev => !prev);
    } catch (error) {
      console.error('Error posting client:', error);
      throw error;  // Optional: Re-throwing error for further handling if needed
    }
  };
  
  

  const LoadClients = async () => {
    setLoading(true);  // Assuming you have a loading state to set
  
    try {
      // Get the current authenticated user's username
      const username = await getCurrentUsername();
  
      // Build the URL with the query parameter
      const url = new URL('https://admin.yosaapp.com/api/Clients/');
      url.searchParams.append('username', username);
  
      // Encode the admin credentials for basic authentication

  
      // Set up the request
      const request = new Request(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,  // Use the encoded authorization header
        },
      });
  
      // Fetch the data
      const response = await fetch(request);
      if (!response.ok) {
        throw new Error('Failed to fetch clients');
      }
  
      // Parse the JSON response
      const data = await response.json();
      setClients(data);  // Assuming you have a state setter for clients
    } catch (error) {
      console.error('Error loading clients:', error);
    } finally {
      setLoading(false);
    }
  };

  const beforeUploadName = (e) => {
    const name = e.target.value;
    const isValidName = name.trim() !== ''; 
    if (!isValidName) {
      message.error('Name cannot be empty!');
    }
    setNewWorksheet(isValidName ? { ...newWorksheet, name } : { ...newWorksheet, name : '' });
    return false; // Prevents default behavior if necessary
  };
  

  const beforeUpload = useCallback((file) => {
    setNewWorksheet({ ...newWorksheet, file });
    return false; // Prevent automatic upload
  }, [newWorksheet]);

const checkUserAndNavigate = async (goToHome, goToDashboard) => {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    //console.log(`The username: ${username}`);
    //console.log(`The userId: ${userId}`);
    //console.log(`The signInDetails: ${signInDetails}`);
    return username;
  } catch (err) {
    console.error('Error getting current user:', err);
    throw err;
  }
};

useEffect(() => {
  

  
  goToHome();
  LoadClients();
  LoadWorksheets();
  

}, [refresh]);

const handleCancelWorksheet = useCallback(() => {

  nameInputRef.current.clearValue();
  setNewWorksheet({ name: '', file: null });
  setIsWorksheetModalVisible(false);
  
}, []);

const handleCancelInvite = useCallback(() => {

  nameInputRef.current.clearValue();
  emailInputRef.current.clearValue();
  setIsInviteModalVisible(false);

}, []);

  const handleCancel = ()  => {

    setIsAssignModalVisible(false);
    setPdfModalVisible(false);
    
  };

  const handleUpload = (values) => {
    //console.log("handle upload called sadfuisadgf");
    //console.log("Form values:", values);
    const { name, file } = values;
    //console.log("This is important, name: ", file);
    Storage.put(name, file).then(resp => {
      //console.log("Upload successful:", resp);
    }).catch(err  => {console.log(err);});
  
    
  
    if (!file || !file.fileList || file.fileList.length === 0) {
      console.error('File is missing or incorrect:', file);
      message.error('Please select a file to upload.');
      return;
    }
  
    const fileToUpload = file.fileList[0].originFileObj;
  
    const newWorksheet = {
      key: (worksheets.length + 1).toString(),
      name: name,
      file: fileToUpload // Corrected this line to get the file object
    };
  
    //console.log("New worksheet:", newWorksheet);
  
    setWorksheets([...worksheets, newWorksheet]);
    setIsWorksheetModalVisible(false);
  };
  
  const openPdf = (pdfUrl) => {
    //console.log('Opening PDF...', pdfUrl);
    window.open(pdfUrl, '_blank');
  };
  
  const openPdfModal = (fileUrl) => {
    setPdfUrl(fileUrl);
    setPdfModalVisible(true);
  };
  


  
  
  

  const handleNameChange = (e) => {
    setNewWorksheet({ ...newWorksheet, name: e.target.value });
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'done') {
      setNewWorksheet({ ...newWorksheet, file: info.file.originFileObj });
    }
  };

  const handleClientNameChange = (e) => {
    setNewClient({ ...newClient, name: e.target.value });
  };

  const handleClientEmailChange = (e) => {
    setNewClient({ ...newClient, email: e.target.value });
  };

  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [clientToDelete, setClientToDelete] = useState({ clientId: null, clientEmail: null });
  const showDeleteConfirmModal = (clientId, clientEmail) => {
    setClientToDelete({ clientId, clientEmail });
    setIsDeleteModalVisible(true);
  };
  const handleDeleteConfirm = () => {
    const { clientId, clientEmail } = clientToDelete;
    handleDeleteClient(clientId, clientEmail); // Call your existing delete function
    setIsDeleteModalVisible(false); // Close the modal
  };
  
  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false); // Close the modal without deleting
  };
  const showTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };



  const handleTemplateOk = () => {
    setIsTemplateModalVisible(false);
  };

  const handleTemplateCancel = () => {
    setIsTemplateModalVisible(false);
  };

  const copyToClipboard = () => {
    const text = 
`Welcome To Yosa! 🧠

We’re excited to have you joining us, but first, we need to onboard you to our system!

Yosa isn’t available to the general public; only a select few therapists and clients have access to our platform. 🔒

To ensure the safety of your information and the exclusivity of our services, we have a rather secure onboarding process. So please bear with us! 😅

Follow these steps to properly register:

	1.	Download the iOS app called “Yosa” from the App Store. (Our logo is a brain.)
	2.	Enter the 10-digit code your therapist provided for you in the given field. (You’ll see what we mean!)
	3.	Enter your name, email, and password.
	4.	Verify your email address.
	5.	Sign in with your new credentials.
	6.	Smooth sailing, off you go!

Please don’t hesitate to reach out to support@yosaapp.com with any questions or concerns!

We’re more than happy to have you on board! 🎉`;
    navigator.clipboard.writeText(text).then(() => {
      message.success('Text copied to clipboard');
    }).catch(() => {
      message.error('Failed to copy text');
    });
  };

  const confirmDelete = () => {
    // Perform the delete action here
    deleteWorksheet(worksheetToDelete.id, worksheetToDelete.name, worksheetToDelete.file);
    setIsDeleteWorksheetModalVisible(false);
    setWorksheetToDelete(null);
  };

  const cancelDelete = () => {
    setIsDeleteWorksheetModalVisible(false);
    setWorksheetToDelete(null);
  };

  const [isDeleteWorksheetModalVisible, setIsDeleteWorksheetModalVisible] = useState(false);
  const [worksheetToDelete, setWorksheetToDelete] = useState(null);

  const clientsWithKey = clients.map((client, index) => ({ ...client, key: index }));
  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false);
  const showDeleteConfirmation = () => {
    setIsDeleteAccountModalVisible(true);
  };

  const { Header, Content, Footer, Sider } = Layout;
  const items = [
    { key: '1', icon: <UserAddOutlined />, label: 'Add Clients', onClick: () => setIsInviteModalVisible(true) },
    { key: '2', icon: <UploadOutlined />, label: 'Upload Worksheet', onClick: () => setIsWorksheetModalVisible(true) },
    { 
      key: '3', 
      icon: <LogoutOutlined />, 
      label: 'Logout', 
      onClick: async () => {
        try {
          await signOut();
          goToHome();
        } catch (error) {
          console.error('Error signing out:', error);
        }
      } 
    },
    {
      key: '4',
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      label: <span style={{ color: 'red' }}>Delete Account</span>,
      onClick: showDeleteConfirmation,
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


 

  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Provider Dashboard</title>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>
      <div className='wholescreen'>
        <Layout>
          <div className='sidebar'>
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                //console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                //console.log(collapsed, type);
              }}
            >
              <div className="demo-logo-vertical" />
              <Menu className='sidebar' theme="dark" mode="inline" defaultSelectedKeys={[]} items={items} />
            </Sider>
          </div>
          <Layout>
            <Header
              className='header'
              style={{
                padding: 40,
                fontWeight: 'bold',
                fontSize: 25,
                fontFamily: 'Mulish',
              }}
            >
              <div>
                Welcome Back!
              </div>
            </Header>
            <Content
              className="scrollable-content" /* Add class to make content scrollable */
              style={{
                height: '100vh',
                overflowY: 'auto', /* Enable vertical scrolling */
              }}
            >
              <div className='dashboard-content'>
              <Spin spinning={loading}> {/* Add Spin component to show loader */}
                <div className='top-section'>
                <div className='clients-section'>
                  <h2 className='tablename clientname'>My Clients</h2>
                  <Table
              className='clients-table'
                    columns={columns}
                    expandable={{
                      expandedRowRender,
                      expandIcon: ({ expanded, onExpand, record }) => (
                        <button 
                          onClick={e => onExpand(record, e)} 
                          style={{ 
                            background: '#AC8200', 
                            color: 'white',
                            border: '0px solid #ccc',
                            borderRadius: '7px', 
                            cursor: 'pointer',
                            padding: '5px 10px',
                            fontSize: '15px',
                            marginLeft: '5px'
                          }}
                        >
                          {expanded ? 'Collapse' : 'Expand'}
                        </button>
                      ),
                      onExpand: (expanded, record) => {
                        if (expanded) {
                          LoadClientWorksheets(record.email);
                          setExpandedRowKeys([record.key]);
                        } else {
                          setExpandedRowKeys([]);
                        }
                      },
                    }}
                    expandedRowKeys={expandedRowKeys}
                    dataSource={clientsWithKey}
                    pagination={false}
                    showHeader={clients.length > 0}
                    locale={{
                      emptyText: (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={yellowshirtlady} alt="No Clients" className='greentguy'/>
                          <p className='nothingtext' style={{ marginLeft: '10px' }}>Nothing here yet... click "Add Clients" on the sidebar to add your first client!</p>
                        </div>
                      ),
                    }}
                    size="middle"
                  />
                </div>
                  
                </div>
                </Spin>
                <div className='top-section'>
                <div className='worksheet-section' style={{ height: '100vh', overflowY: 'auto' }}>
                    <h2 className='tablename'>My Worksheets</h2>
                    <Table
                      className='worksheet-table'
                      columns={columnsWorksheet}
                      dataSource={worksheets.length > 0 ? worksheets : []}
                      pagination={false}
                      showHeader={worksheets.length > 0}
                      locale={{
                        emptyText: (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={greentguy} alt="No worksheets" className='greentguy'/>
                            <p className='nothingtext' style={{ marginLeft: '10px' }}>Nothing here yet... click "Upload Worksheet" on the sidebar to the left to get started!</p>
                          </div>
                        ),
                      }}
                      size="middle"
                    />
                  </div>
                  <div className='balancingguy'>
                    <img src={balancingguy} alt='Yosa Guy' />
                  </div>
                  </div>
              </div>
              <Footer className="footer">
                <p>&copy; 2024 Yosa Inc. All rights reserved.</p>
                <p>Having problems? Don't hesitate to contact <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.</p>
              </Footer>
            </Content>
          </Layout>
        </Layout>

        <Modal
  title="Confirm Delete"
  visible={isDeleteWorksheetModalVisible}
  onOk={confirmDelete}
  onCancel={cancelDelete}
  okText="Delete"
  cancelText="Cancel"
>
  <p>Heads up! Deleting this worksheet will mean the client cannot view it in their app. Are you sure you want to delete?</p>
</Modal>

<Modal
  title={
    <>
      <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Upload Worksheet</div>
      <div style={{ marginTop: '10px' }}>We only accept pdf files (the ones that end in .pdf!) And they have to be under 50MB in size.</div>
      <div style={{ marginTop: '10px', fontWeight: 'normal' }}>Click <strong>Select File</strong> below to get started with your worksheets.</div>
      <div style={{ marginTop: '20px', fontWeight: 'normal' }}>After uploading a worksheet you can assign it to a client by clicking <strong>Send Assignment/Delete Client</strong> under the action label and clicking <strong>Send Assignment</strong>.</div>
    </>
  }
  visible={isWorksheetModalVisible}
  onCancel={handleCancelWorksheet}
  footer={[
    <Button key="cancel" onClick={handleCancelWorksheet}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      onClick={() => {
        const form = document.getElementById('worksheetForm');
        if (form) {
          form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      }}
    >
      OK
    </Button>,
  ]}
>
  <Form id="worksheetForm" onFinish={handlePostWorkSheet} layout="vertical">
          <Form.Item >
            <InputComponent label="Name" required ref={nameInputRef}
            placeholder="Write here"
             />
          </Form.Item>
          <Form.Item label="File" name="file" required>
            <Upload
              beforeUpload={beforeUpload}
              fileList={newWorksheet.file ? [newWorksheet.file] : []}
              onRemove={() => {
                setNewWorksheet({ ...newWorksheet, file: null });
              }}
            >
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ display: 'none' }}>
        Submit
      </Button>
    </Form.Item>
  </Form>
</Modal>

<Modal
        title="Are you sure?"
        visible={isDeleteAccountModalVisible}
        onOk={handleDeleteAccount}
        onCancel={() => setIsDeleteAccountModalVisible(false)}
        okText="Delete"
        okButtonProps={{ style: { backgroundColor: 'red', color: 'white' } }}
      >
        Are you sure you want to delete your account? This action cannot be undone.
      </Modal>

<Modal zIndex={1001} 
  title="Template" 
  visible={isTemplateModalVisible} 
  onOk={handleTemplateOk} 
  onCancel={handleTemplateCancel}
  okText="Done"
  width={800} title="Copy this text and forward it to your client!" visible={isTemplateModalVisible} onOk={handleTemplateOk} onCancel={handleTemplateCancel}>
      <p>
  <strong>Welcome To Yosa! 🧠</strong><br />
  <span style={{ display: 'block', marginTop: '20px' }}>We're excited to have you joining us, but first, we need to onboard you to our system!<br /></span>
  <span style={{ display: 'block', marginTop: '20px' }}>Yosa isn't available to the general public; only a select few therapists and clients
    have access to our platform. 🔒
  </span>
  <span style={{ display: 'block', marginTop: '20px' }}>To ensure the <strong>safety</strong> of your information and the <strong>exclusivity</strong> of our services, we have a rather secure onboarding process. So please bear with us! 😅
  </span>
  <span style={{ display: 'block', marginTop: '20px' }}>Follow these steps to properly register:</span>
</p>
<ol>
  <li>Download the iOS app called "Yosa" from the App Store. (Our logo is a brain.) </li>
  <li>Enter the 10-digit code your therapist provided for you in the given field. (You'll see what we mean!)</li>
  <li>Enter your name, email, and password.</li>
  <li>Verify your email address.</li>
  <li>Sign in with your new credentials.</li>
  <li>Smooth sailing, off you go!</li>
</ol>
<span style={{ display: 'block', marginTop: '20px' }}>Please don't hesitate to reach out to <a href="mailto:support@yosaapp.com">support@yosaapp.com</a> with any questions or concerns!</span>
<span style={{ display: 'block', marginTop: '20px' }}>We're more than happy to have you on board! 🎉</span>
<span style={{ display: 'block', marginTop: '20px' }}></span>
      <Tooltip 
  title="Click here" 
  //Style={{ backgroundColor: 'white', color: 'black' }}
>
  <Button 
    onClick={copyToClipboard} 
    style={{ backgroundColor: 'black', color: 'white' }}
  >
    Copy
  </Button>
</Tooltip>
</Modal>

<Modal
      title="Please Read Carefully."
      visible={isDeleteModalVisible}
      onOk={handleDeleteConfirm}
      onCancel={handleCancelDelete}
      okText={<strong>Delete</strong>}
      okButtonProps={{ style: { backgroundColor: '#a00', color: 'black', border: 'none' } }}
    >
      <div style={{ marginBottom: '20px' }}> {/* Add a div with marginBottom for spacing */}
    <p>
      <strong>Are you certain you wish to delete this client?</strong>
      <br />
      <br />
      Any assigned work will be deleted for both you and your client. Your client's notification will be removed from their app.
      <br />
      <br />
      If you believe you entered the incorrect email for your client and the registration status
       is <strong>"Unregistered"</strong> even though your client claims they have properly registered,
       proceed to delete the profile and enter the correct email.
      <br />
      <br />
      If the correct email is entered and the client is registered in the iOS app, the registration status will
      update to <strong>"Registered"</strong> if you click <strong>"Check Registration Status"</strong>.
      <br />
      <br />
      If you are unsure how to proceed, please contact <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.
      
    </p>
  </div>
  </Modal>


<Modal zIndex={1000} title="New Client Invite" visible={isInviteModalVisible} onOk={handleInviteOk} onCancel={handleCancelInvite} okText="Add Client">
  <div>
    <strong>READ CAREFULLY</strong>
    <p>In order to ensure the safety of your clients' sensitive information, you must closely follow these instructions. To add clients in our database, follow these steps:</p>

<ol>
  <li><strong>Input Name and Email:</strong> Correctly input the client's name and email into the fields below.</li>
  <li><strong>Send Client Template:</strong> Click <strong>"Template"</strong> below. Then copy the provided template and forward it to your client via a secure channel, asking them to closely follow along. (Text and email should suffice)</li>
  <li><strong>Provide Code</strong> You should have receieved a document containing anonymous codes with which your clients will register, the registration process is intuitive! Please forward them a code of your choosing.</li>
  <li><strong>Client Registration:</strong> Once they have registered properly, you will click the <strong>"Check Registration Status"</strong> button, and if they have registered at the given email, it will update to <strong>"Registered"</strong> in the clients table!</li>
</ol>

If you have issues you cannot resolve, please contact <a href="mailto:support@yosaapp.com">support@yosaapp.com </a>. 
<br />
&nbsp;

  </div>
  <Form layout="vertical">
        <Form.Item required>
          <InputComponent label="Name" required ref={nameInputRef} placeholder="Write here" />
        </Form.Item>
        <Form.Item required>
          <InputComponent label="Email" required ref={emailInputRef} placeholder="Write here" />
        </Form.Item>
  </Form>
  <p 
        style={{ textAlign: 'center', fontSize: '20px', color: 'blue', textDecoration: 'underline' }}
        onClick={showTemplateModal}
      >
        <strong>Template</strong>
      </p>
</Modal>

        <Modal
  title={<div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Send Assignment</div>}
  visible={isAssignModalVisible}
  onCancel={handleCancel}
  footer={[
    <Button key="cancel" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      onClick={() => {
        const form = document.getElementById('assignmentForm');
        if (form) {
          form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
        
      }}
    >
      OK
    </Button>,
  ]}
>
  <Form
    id="assignmentForm"
    onFinish={() => handlePostTherapySheet(selectedClientKey)}
    layout="vertical"
    
  >
    <p>When you send an assignment, your client will be notified in the app and asked to complete it. Upon deleting an assignment, the notification will be removed from your client's app.</p>
    <br />
  
    <p>Upon completion, the assignment will be marked as complete in the drop down menu labeled <strong>Expand</strong> next to yout client's name and you will be able to view their response by clicking <strong>View Response</strong>.</p>
    <br />
    {worksheets.length > 0 ? (
      worksheets.map(worksheet => (
        <Form.Item key={worksheet.id}>
          <Checkbox
            value={worksheet.id}
            checked={selectedWorksheets.includes(worksheet.id)}
            onChange={(e) => {
              const key = e.target.value;
              if (e.target.checked) {
                setSelectedWorksheets([key]);
              } else {
                setSelectedWorksheets([]);
              }
            }}
          >
            {worksheet.name}
          </Checkbox>
        </Form.Item>
      ))
    ) : (
      <p>You have not uploaded any worksheets yet. Click "Upload Worksheet" on the sidebar to get started!</p>
    )}
    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ display: 'none' }}>
        Submit
      </Button>
    </Form.Item>
  </Form>
</Modal>

        <Modal
        title="View PDF"
        visible={pdfModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
        width="80%"
        style={{ top: 20 }}
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '80vh' }}
            frameBorder="0"
          ></iframe>
        )}
      </Modal>
      </div>
    </>
  )
};

export default Dashboard;
