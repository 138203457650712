// src/components/providerdashboard/ClientsPanel.js
import React from 'react';
import ClientDetails from './ClientsDetails';
import MoreActionsDropdown from './MoreActionsDropdown';

function ClientsPanel({ clients, onEditClient, onDeleteClient, onAssignWorksheet, onToggleClientDetails, expandedClient }) {
  return (
    <div className="clients-panel">
      <h2>My Clients</h2>
      <ul>
        {clients.map(client => (
          <li key={client.id}>
            <div onClick={() => onToggleClientDetails(client.id)}>
              {client.name}
            </div>
            {expandedClient === client.id && (
              <ClientDetails client={client} onAssignWorksheet={onAssignWorksheet} />
            )}
            <button onClick={() => onEditClient(client.id)}>Edit</button>
            <MoreActionsDropdown onDelete={() => onDeleteClient(client.id)} onAssign={() => onAssignWorksheet(client.id)} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ClientsPanel;