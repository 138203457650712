// src/components/landingpage/Home.jsx
import React from 'react';
import '../../styles/Home.css';
import yosaLogo from '../../images/yosalogo_color_changed.png';
import homePageMobile from '../../images/homepagemobile.png';
import homePageApp from '../../images/homepageapp.png';
import gratitude from '../../images/gratitude.png';
import jumping from '../../images/jumping.png';
import mealDonations from '../../images/meal donations.png';
import meditation from '../../images/meditation.png';
import talkTherapy from '../../images/talk-therapy.png';
import therapy from '../../images/therapy.png';
import wellness from '../../images/wellness101.png';
import yoga from '../../images/yoga.png';
import yellowshirtlady from '../../images/yellowshirtlady.png';
import homeworkicon from '../../images/homeworkicon.png';
import { Button, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import CarouselComponent from '../CarouselComponent';
import { useState } from 'react';
import linkedinicon from '../../images/linkedinicon.png';

const Home = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const goToSignUp = () => {
        navigate('/signup');
    };

    const goToLogIn = () => {
        navigate('/login');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch('https://admin.yosaapp.com/api/Waitlist/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                console.log("error");
            }

            alert('You successfully joined our waitlist! We will contact you soon.');
            setEmail('');
        } catch (error) {
            setError('There was a problem submitting your email. Please try again later.');
        }
    };


    return (
        <>
            <html>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Yosa Landing Page</title>
                <style>@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');</style>
            </head>
            <header>
                <img src={yosaLogo} alt="Yosa Logo" />
                <nav>
                <ul>
                    <li><Button className='login-button' onClick={goToSignUp}>Sign Up</Button></li>
                    <li><Button className='login-button' onClick={goToLogIn}>Log In</Button></li>
                </ul>
                </nav>
            </header>
        
            <section id="therapy" className="opening-content-container">
                <div className="left-content">
                <h1 className="tagline">Modernizing Therapy for a Healthier Tomorrow</h1>
                <form id="email-form" onSubmit={handleSubmit}>
                    <label htmlFor="email" className="enter-email medium-text">Interested in Yosa?<br />Enter your email below to join our <strong>waitlist</strong>.</label>
                    <div className="lower-email">
                    <input type="email" id="email" name="email" placeholder="Enter email here" value ={email} onChange={handleEmailChange}/>
                    <button type="submit">Submit</button>
                    </div>
                    <label htmlFor="email" className="enter-email small-text">
  Questions? Reach out to <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>!
</label>
                </form>
                </div>
                <div className="right-content">
                    <img src={homePageApp} alt="Therapy App Interface" />
                </div>
            </section>

            <section id="homework" className="second-content-container">
                <div className="top-content">
                <h1 className="lighter-bold yosa-for">Yosa for your therapy practice</h1>
                </div>
                <div className="middle-content">
                <div className="box">
                    <p className="lighter-bold">Upload and send custom assignments to patients to complete</p>
                </div>
                <div className="box">
                    <p className="lighter-bold">HIPAA compliant</p>
                </div>
                <div className="box">
                    <p className="lighter-bold">A one stop shop for any tools your patients might need between sessions</p>
                </div>
                </div>
                <div className="bottom-content">
                <img src={talkTherapy} alt="Handouts Icon" />
                <img src={therapy} alt="Therapy Icon" />
                <img src={meditation} alt="Meditation Icon" />
                <img src={mealDonations} alt="Meal Donation Icon" />
                <img src={yoga} alt="Yoga Icon" />
                <img src={gratitude} alt="Gratitude Icon" />
                <img src={wellness} alt="Wellness Icon" />
                </div>
            </section>

            <section class="fourth-content-container">
                <div className="left-side" id="left">
                    <h1 id="title" className='highlight-title-text'>Homework is Essential in Therapy</h1>
                    <p id="description" className="even-lighter-bold">Therapeutic homework bridges the gap between therapy sessions and everyday life by having patients engage with what they learned <i className='bold-text'>in</i> therapy, <i className='bold-text'>outside</i> of therapy hours as well. Examples include self-monitoring, psychoeducational homework, and behavioral practices. As an individual might only go to therapy weekly or biweekly, continous monitoring between sessions provides a more representative picture of a patient's week.
                    </p>
                    <br></br>
                    <h1 id="title" className="even-lighter-bold">Homework is causally linked with <span className='highlight-text'>better patient outcomes</span>. Yet, <span className='highlight-text'>just 50%</span> of assignments are completed.
                        <a href="https://doi.org/10.1111/j.1468-2850.2010.01204.x" className="reference-link"><span className='reference-link'>(</span>Kazantzis et al., 2010</a><span className="reference-link">; </span> 
                        <a href="https://doi.org/10.1016/j.beth.2016.05.002" className="reference-link">Kazantzis et al., 2016</a><span className="reference-link">; </span>
                        <a href="https://doi.org/10.1177/0145445504272979 " className="reference-link">Gaynor et al., 2006</a><span className="reference-link">; </span>
                        <a href="https://doi.org/10.1017/s1352465804001365" className="reference-link">Helbig & Fehm, 2004</a>
                        <span className='reference-link'>)</span></h1>
                </div>
                <div>
                    <div className="homework-container" id="right">
                    <   img className="homeworkicon" src={homeworkicon} alt="Homework Pic" />
                    </div>
                </div>
            </section>

            <section className="third-content-container">
                <div className="left-side" id='jumpingladyside'>
                    <img id='jumpinglady' src={yellowshirtlady} alt="Jumping Pic" />
                </div>
                <div className="right-side">
                <h1 id="title" className="even-lighter-bold">A Better Way of Delivering Homework</h1>
                <p id="description" className="even-lighter-bold">Low homework compliance due to <span className='highlight-text'>limitations with current delivery methods</span> (paper, email, verbal assignment)<a href="https://doi.org/10.1002/jclp.10027" className="reference-link">(Garland & Scott, 2002)</a><span className="reference-link"></span></p>
                <ul>
                    <li className="even-lighter-bold">Subject to being lost or forgotten</li>
                    <li className="even-lighter-bold">Low accessibility</li>
                    <li className="even-lighter-bold">Unstimulating</li>
                </ul>
                <p id="description" className="even-lighter-bold">Yosa provides <span className='highlight-text'>advantages</span> over current delivery methods</p>
                <ul>
                    <li className="even-lighter-bold">Increased accessibility and convenience</li>
                    <li className="even-lighter-bold">Easier progress tracking and pattern identification, which may be useful in personalizing treatment to patients</li>
                    <li className="even-lighter-bold">Increased engagement = increased compliance = better patient outcomes</li>
                        <ul>
                            <li className="even-lighter-bold">Interactive features, gamification, reminders, notifications, stimulating user interface, and user customization</li>
                        </ul>
                </ul>
                </div>
            </section>

            <section class="fourth-content-container">
                <div className="left-side" id="left">
                <h1 id="title" className='highlight-title-text'>Features</h1>
                <p id='description' className="even-lighter-bold">Worksheet Delivery:</p>
                <ul>
                    <li className="even-lighter-bold">
                    Providers can upload their own custom PDF "homework assignment" and assign it to a patient. On the patient side, patients will be able to see what PDFs have been uploaded for them and respond to the assignment on the app.
                    </li>
                </ul>
                <p id='description' className="even-lighter-bold">Therapy Journal:</p>
                <ul>
                    <li className="even-lighter-bold">
                    Allows patients to track therapy sessions, aiming to increase patient engagement, and help patients become active participants in therapy by helping think through session expectations, pre-session topics, and post-session reflections.
                    </li>
                </ul>
                <p id='description' className="even-lighter-bold">Tracking:</p>
                <ul>
                    <li className="even-lighter-bold">
                    Mood, symptom, sleep, medication, and substance use trackers
                    </li>
                </ul>
                {/*<p id='description'>Validated Measures</p>
                <ul>
                    <li>
                    Assign the PHQ-9 or GAD-7 to your patients to complete.
                    </li>
                </ul>>*/}
                <p id='description' className="even-lighter-bold">Digitalization For Increased Safety:</p>
                <ul>
                    <li className="even-lighter-bold">
                    In moments of crisis, a paper safety plan is not much help. Digitalization increases accessibility and outcomes. Shareable to keep loved ones and your care team in the loop. 
                    </li>
                </ul>
                </div>
                <div className="right-side" id="right">
                    <CarouselComponent />
                </div>
            </section>
            </html>
            <footer className="footer">
  <div className="footer-content">
    <p>&copy; 2024 Yosa Inc. All rights reserved.</p>
    <nav>
      <ul>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
        <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
        <li><a href="/terms-of-service">Terms of Service</a></li>
        <li><a href="/support">Support</a></li>
      </ul>
    </nav>
    <div className="footer-bottom">
      <p>
        <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
        <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
        </a>
      </p>
    </div>
    <div className="footer-bottom">
      <form id="waitlist-email-form" onSubmit={handleSubmit} className="email-form">
        <div className="email-form-group">
          <label htmlFor="email" className="waitlist-label footer-text">Enter your email to join our waitlist.</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter email here"
            value={email}
            onChange={handleEmailChange}
            className="email-input"
          />
          <button type="submit" className="waitlist-email-form-button">Submit</button>
        </div>
      </form>
    </div>
  </div>
</footer>
        </>
    )
}

export default Home;