// src/components/providerdashboard/WorksheetUploadModal.js
import React, { useState } from 'react';

const WorksheetUploadModal = ({ onSubmit, onClose }) => {
  const [worksheet, setWorksheet] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ worksheet });
    onClose();
  };

  return (
    <div className="modal">
      <form onSubmit={handleSubmit}>
        <label>Worksheet:</label>
        <input type="text" value={worksheet} onChange={(e) => setWorksheet(e.target.value)} required />
        <button type="submit">Upload</button>
      </form>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default WorksheetUploadModal;