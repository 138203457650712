// src/components/providerdashboard/CarouselComponent.jsx
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import safetyPlanMobile from '../assets/safetyplanmobile.png';
import helppic from '../assets/helppic.png';
import homeworkpic from '../assets/hwdemopic.png';
import moodlog from '../assets/moodlog.png';
import therapyjournalpic from '../assets/therapyjournalpic.png';
import validatedmeasures from '../assets/validatedmeasures.png';

const CarouselComponent = () => {
  return (
    <div className="custom-carousel">
        <Carousel autoPlay interval={5000} infiniteLoop>
        <div>
            <img src={homeworkpic} alt="Homework Pic" />
        </div>
        <div>
            <img src={therapyjournalpic} alt="Therapy Journal Pic" />
        </div>
        <div>
            <img src={moodlog} alt="Mood Log" />
        </div>
        <div>
            <img src={validatedmeasures} alt="Validated Measures" />
        </div>
        <div>
            <img src={safetyPlanMobile} alt="Safety Plan" />
        </div>
        <div>
            <img src={helppic} alt="Help Pic" />
        </div>
        </Carousel>
    </div>
  );
};

export default CarouselComponent;