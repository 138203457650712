import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import axios from 'axios';
import yosaLogo from '../images/yosalogo.png';
import signUpPic from '../images/signup.png';
import '../styles/SignUp.css';

const poolData = {
    UserPoolId: 'us-east-1_2Pbc3zfmr',
    ClientId: '7q641c75ljpv2snpitel88muip',
};

const userPool = new CognitoUserPool(poolData);

const Login = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    const onSignIn = (email, password) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                console.log('Login Success:', result);
                const idToken = result.getIdToken().getJwtToken();
                axios.post('https://admin.yosaapp.com/api/UserToken/obtain_pair/', {
                    username: email,
                    //password: password,
                    id_token: idToken,
                })
                .then(response => {
                    const { access, refresh } = response.data;
                    localStorage.setItem('access_token', access);
                    localStorage.setItem('refresh_token', refresh);
                    navigate('/dashboard');
                })
                .catch(error => {
                    console.error('Backend Login Error:', error);
                    setErrorMessage('Failed to log in. Please try again.', error);
                });
            },
            onFailure: (err) => {
                console.error('Login Error:', err);
                setErrorMessage(err.message || 'An error occurred during login.');
            },
        });
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        onSignIn(values.username, values.password);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const goToSignUp = () => {
        navigate('/signup');
    };

    const goToForgotPassword = () => {
        navigate('/forgotpassword');
    };

    return (
        <>
            <section className='signup-container'>
                <div className='signup-left'>
                    <img id='signupimage' src={signUpPic} alt='Yosa Lady' />
                </div>
                <div className='signup-right'>
                    <img className='signuplogo' src={yosaLogo} alt="Yosa Logo" />
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {errorMessage && (
    <div className='error-message' style={{ color: 'red' }}>
        <p>{errorMessage}</p>
    </div>
)}


<div className='center'>
                            <Button className='forgot-password' onClick={goToSignUp}>Don't have an account? Sign up here</Button>
                            <Button className='forgot-password' onClick={goToForgotPassword}>Forgot Password? Click here</Button>
                            <Form.Item>
                                <Button className='submit-button' type="primary" htmlType="submit">
                                    Log In
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </section>
        </>
    );
};

export default Login;
