import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import yosaLogo from '../images/yosalogo.png';
import signUpPic from '../images/signup.png';
import '../styles/SignUp.css';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { confirmResetPassword } from 'aws-amplify/auth';


const poolData = {
    UserPoolId: 'us-east-1_2Pbc3zfmr',
    ClientId: '7q641c75ljpv2snpitel88muip',
};

const userPool = new CognitoUserPool(poolData);

const ConfirmResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const email = location.state?.email || ""; // Retrieve the email from the state

    const handleConfirmResetPassword = async ({ username, confirmationCode, newPassword }) => {
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });
            console.log('Password reset confirmed successfully.');
            goToLogin();
        } catch (error) {
            console.error('Confirm Password Reset Error:', error);
            setErrorMessage(error.message || 'An error occurred during password reset confirmation.');
        }
    };

    const onFinish = (values) => {
        const { email, code, password } = values;
        console.log('Confirm Reset Password Form Values:', values);

        

        handleConfirmResetPassword({ username: email, confirmationCode: code, newPassword: password });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Confirm Reset Password Form Failed:', errorInfo);
        setErrorMessage('Please fill in all required fields.');
    };

    const goToLogin = () => {
        navigate('/login');
    };

    return (
        <>
            <section className='signup-container'>
                <div className='signup-left'>
                    <img id='signupimage' src={signUpPic} alt='Yosa Lady' />
                </div>
                <div className='signup-right'>
                    <img className='signuplogo' src={yosaLogo} alt="Yosa Logo" />
                    <p className="logo-text">A confirmation code was sent to the following address: </p>
                    <Form
                        name="confirm-reset-password-form"
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            email: email, // Set the initial value of the email input field
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item label="Email: " name="email" style={{ marginBottom: '10px' }}>
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Confirmation Code"
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the confirmation code!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="New Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your new password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm New Password"
                            name="confirmpassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your new password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {errorMessage && (
                            <div className='error-message'>
                                <p style={{ color: 'red' }}>{errorMessage}</p>
                            </div>
                        )}

                        <div className='center'>
                            <Button className='forgot-password' onClick={goToLogin}>Remembered your password? Log in here</Button>
                            <Form.Item>
                                <Button className='submit-button' type="primary" htmlType="submit">
                                    Confirm Password Reset
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </section>
        </>
    );
};

export default ConfirmResetPassword;