import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import linkedinicon from '../images/linkedinicon.png';
import yosaLogo from '../images/yosalogo.png';
import '../styles/SignUp.css';

const NoticeOfPrivacyPractices = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const goToSignUp = () => {
        navigate('/signup');
    };

    const goToLogIn = () => {
        navigate('/login');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch('https://admin.yosaapp.com/api/Waitlist/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                console.log("error");
            }

            alert('You successfully joined our waitlist! We will contact you soon.');
            setEmail('');
        } catch (error) {
            setError('There was a problem submitting your email. Please try again later.');
        }
    };

    return (
        <>
            <html>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Yosa Landing Page</title>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
                    
                </style>
            </head>
            <body>
                <header className="policyheader">
                    <a href="/">
                        <img src={yosaLogo} alt="Yosa Logo" />
                    </a>
                    <nav>
                        <ul>
                            <li><Button className='login-button' onClick={goToSignUp}>Sign Up</Button></li>
                            <li><Button className='login-button' onClick={goToLogIn}>Log In</Button></li>
                        </ul>
                    </nav>
                </header>
                <main>
  <h1 className="privacy-title">Notice of Privacy Practices</h1>
  <p className="last-updated">Last Updated: August 6, 2024</p>
  <div className="content-container">
    
    <p className="policy-header">This Notice Describes How Medical Information About You May Be Used and Disclosed and How You Can Get Access to This Information. Please Review It Carefully.</p>
    <p>
      This Notice is provided to you pursuant to the privacy regulations enacted as a result of the Health Insurance Portability and Accountability Act of 1996 (HIPAA). This notice of privacy practices describes how Yosa Inc. (referred to in this Notice as “Yosa”) may use and disclose your health information and how you can access this information. This Notice applies to all the health information created or received by Yosa Inc. and clarifies our responsibilities and your rights regarding your health information. Please review this notice carefully.
    </p>

    <p className="policy-header">A. Our Commitment to Your Privacy</p>
    <p>
      Yosa is committed to protecting the privacy and security of your health information. As mandated by law, we are responsible for maintaining the privacy of your health information, providing you with this notice detailing our legal duties and privacy practices regarding your health information, adhering to the terms currently outlined in this notice, and notifying you in the event of a breach of your health information. This document also outlines:
    </p>
    <ul>
      <li>How we may use and disclose your health information;</li>
      <li>Your rights regarding your privacy; and</li>
      <li>Our obligations in the handling of your health information.</li>
    </ul>

    <p className="policy-header">B. Persons/Entities Covered by This Notice</p>
    <p>
      Yosa Inc. provides a comprehensive software platform that supports therapists through a web-based interface and patients via a mobile app. This notice describes the practices of Yosa Inc. related to the collection, use, and protection of Protected Health Information (PHI) handled through both the web interface used by therapists and the mobile application used by patients, pursuant to the Health Insurance Portability and Accountability Act of 1996 (HIPAA).
    </p>
    <p><strong>Important:</strong></p>
    <ul>
      <li>PHI Access and Use: Yosa's access to PHI is limited to what is necessary to maintain the platform and support its use by both therapists and patients. This access is managed under strict data security protocols to ensure confidentiality and integrity.</li>
      <li>Liability: Yosa is committed to providing a secure environment for PHI but is not responsible for the individual compliance practices of therapists or the security measures patients might use on their personal devices. It is important for all users to follow best practices for data security, particularly when accessing health information through devices.</li>
    </ul>

    <p className="policy-header">C. Contact for Questions</p>
    <p>
      For more information or questions about the privacy policies of Yosa Inc., please contact:
    </p>
    <p>
      Privacy Officer<br />
      (508) 369-9939<br />
      <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>
    </p>

    <p className="policy-header">D. Use and Disclosure of Your Individually Identifiable Health Information (PHI)</p>
    <p>
      This Notice of Privacy Practices is NOT an authorization; rather it describes how we, our Business Associates, and their subcontractors may use and disclose your Protected Health Information to support the functionality of our platform, enhance user experiences, and for other purposes as permitted or required by law. It also describes your rights to access and control your Protected Health Information.
    </p>
    <p>
      “Protected Health Information” (“PHI”) means information that identifies you individually; including demographic information, and information that relates to your past, present, or future physical or mental health condition and/or related health care services.
    </p>
    <p className="policy-header2">1. Treatment / Platform Functionality</p>
    <p>
        Yosa enables therapists to upload and share therapeutic materials such as PDF worksheets via our web interface. Patients can access and interact with these materials through our mobile app, facilitating ongoing treatment and support. Patients can also monitor various health metrics, create safety plans, and journal. Yosa's role is to provide a secure and efficient platform for these interactions related to treatment; we do not access, use, or disclose the PHI contained within these interactions for any purposes other than to support the functionality of our services.
    </p>

    <p className="policy-header2">2. Operations</p>
    <p>
        Yosa may access PHI to support the operation of our services to ensure that services function smoothly for all users. This access is strictly limited to the necessary personnel and is protected by robust security measures.
    </p>

    <p className="policy-header2">3. User Experience and Feedback</p>
    <p>
        We may use general user activity data to improve the platform and user interface. This does not include direct access to PHI but may involve analyzing usage patterns to enhance the effectiveness of the platform.
    </p>

    <p className="policy-header2">4. Disclosures Required by Law</p>
    <p>
        Yosa will disclose PHI when required by law, such as in response to court orders or legal proceedings, or to comply with regulatory obligations. We ensure that such disclosures are limited to what is legally required.
    </p>

    <p className="policy-header">E. USE AND DISCLOSURE OF YOUR PHI IN CERTAIN SPECIAL CIRCUMSTANCES</p>
    <p className="policy-header2">1. Compliance with Law</p>
    <p>
        Yosa may disclose your PHI when required by law, such as in response to a court order, legal proceedings, or government requests that are legally binding.
    </p>

    <p className="policy-header2">2. Threat to Health or Safety</p>
    <p>
        Yosa may disclose PHI if there is a serious and immediate threat to the health or safety of a person or the public, and the disclosure is necessary to prevent or mitigate the threat, as permitted by law.
    </p>

    <p className="policy-header2">3. Law Enforcement and Legal Requests</p>
    <p>
        Yosa may disclose PHI in response to a law enforcement request as part of a legal process if such disclosure is legally required or necessary to prevent or address fraud, abuse, or other misconduct.
    </p>

    <p className="policy-header2">4. Health Oversight Activities</p>
    <p>
        PHI might be disclosed without authorization for public health and oversight tasks, which may include audits, investigations, inspections, or licensing.
    </p>

    <p className="policy-header">F. Types of Uses and Disclosures That Require Your Authorization</p>
    <p className="policy-header2">1. Research</p>
    <p>
        Any use of PHI for research purposes requires your written authorization unless the research has been approved by an Institutional Review Board and complies with laws governing research.
    </p>

    <p className="policy-header2">2. Marketing Purposes</p>
    <p>
        We will not use or disclose your PHI for marketing purposes without your explicit written authorization.
    </p>

    <p className="policy-header2">3. Sale of Information</p>
    <p>
        Yosa will not sell your PHI without your explicit written authorization.
    </p>

    <p className="policy-header2">4. Sensitive Information</p>
    <p>
        Uses and disclosures of sensitive PHI, such as mental health or substance abuse records, will require your specific authorization unless otherwise permitted or required by law.
    </p>
    <p className="policy-header">G. YOUR PRIVACY RIGHTS REGARDING YOUR PHI</p>

  <p className="policy-header2">1. Inspection and Copies</p>
  <p>
    You may request a copy of, or request to inspect, your PHI that we hold, particularly information that influences the services you receive through our platform. Upon request, we can provide electronic copies or, if requested, a paper copy of your PHI. You may also request a summary of your record. Yosa may charge a reasonable cost-based fee to cover the costs of copying, mailing, labor, and supplies associated with your request. We aim to respond to these requests within 30 days.
  </p>

  <p className="policy-header2">2. Confidential Communications</p>
  <p>
    You can request that we communicate with you about your PHI in more confidential ways, such as through specified phone numbers or email addresses. Yosa will accommodate reasonable requests to communicate through alternative means or at alternative locations to enhance privacy.
  </p>

  <p className="policy-header2">3. Amendment</p>
  <p>
    You may request a correction or amendment of your PHI if you believe it is incorrect or incomplete. Requests must provide a reason or explanation that supports the request. Yosa will consider your request and respond, typically within 60 days. If we cannot amend the information, we will provide you with an explanation and offer you the opportunity to append a statement of disagreement to your record.
  </p>

  <p className="policy-header2">4. Accounting of Disclosures</p>
  <p>
    You may request an accounting of certain disclosures that Yosa has made of your PHI. This accounting will list the disclosures that we have made of your PHI but will not include disclosures made required by law and certain other disclosures (such as any you asked us to make). Your request must be in writing and state the time period for which you want the accounting (not to exceed six (6) years prior to the date you make the request). Yosa will provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within twelve (12) months. Yosa will notify you of the costs involved with any additional request and you may withdraw your request before you incur any costs.
  </p>

  <p className="policy-header2">5. Requests for Restrictions</p>
  <p>
    You may request restrictions on the use or disclosure of your PHI. However, PHI is only used for functionality and technical support. Due to the essential nature of these services, it is not feasible to restrict PHI use in these areas without impacting the service's functionality. Therefore, Yosa will likely deny requests to restrict the use of PHI necessary for these purposes.
  </p>

  <p className="policy-header2">6. Right to Receive a Notice of a Breach of Unsecured Medical Information</p>
  <p>
    You have the right to receive prompt notice in writing of a breach of your PHI that may have compromised the privacy or security of your information.
  </p>

  <p className="policy-header2">7. Right to a Paper Copy of This Notice</p>
  <p>
    You have the right to receive a paper copy of this notice at any time even if you have agreed to receive the notice electronically. You may also obtain a copy of this notice at our website -- yosaapp.com/notice-of-privacy-practices.
  </p>

  <p className="policy-header2">8. Right to File a Complaint</p>
  <p>
    If you believe your rights have been violated, you may file a complaint with us or with the Secretary of the Department of Health and Human Services (“HHS”), Office for Civil Rights, 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting www.hhs.gov/ocr/privacy/hipaa/complaints/. All complaints must be submitted in writing. You will not be penalized for filing a complaint.
  </p>

  <p className="policy-header">H. ADDITIONAL INFORMATION</p>

  <p className="policy-header2">1. Contact Information</p>
  <p>
    When you register with Yosa or update your account, you provide us with your name and email address. We assume that the information you provide is accurate, and you agree to inform us promptly of any changes to this information. Yosa uses this information primarily to manage your account and to enable your use of the platform. We may also use this information to communicate with you about updates, safety information, or changes to our services. As part of the Yosa app, you may voluntarily provide additional contact information within the Safety Plan feature. This may include the names, phone numbers, or email addresses of contacts you designate within steps of the Safety Plan. This information is used only within the app to support your personal safety and wellbeing and is not used for marketing or shared with third parties without your explicit consent. You are responsible for maintaining the accuracy of the information stored in your account and safety plan. You can update or modify your information directly through the app settings.
  </p>

  <p className="policy-header2">2. Business Associates</p>
  <p>
    Your PHI may be disclosed to individuals or entities who provide services to or on behalf of Yosa. This includes hosting services provided by Amazon Web Services (AWS). Pursuant to HIPAA, all business associates are required to enter a business associate agreement (BAA) with Yosa that mandates them to protect the PHI they process and to comply with the relevant privacy and security obligations under HIPAA and other applicable laws before we disclose your PHI to them. However, Yosa generally does not control the business, privacy, or security operations of our business associates.
  </p>

  <p className="policy-header2">3. Authorization for Other Uses and Disclosures</p>
  <p>
    Yosa will obtain your written authorization for uses and disclosures that are not identified by this notice or otherwise required or permitted by applicable law. Any authorization you provide regarding the use and disclosure of your PHI may be revoked at any time in writing. After you revoke your authorization, we will no longer use or disclose your PHI for the reasons described in the authorization. However, your revocation will not affect actions we have already taken; in other words, we are unable to take back any disclosures of PHI we have already made.
  </p>

  <p className="policy-header">How does Yosa help protect your health information and keep it secure?</p>

  <p>
    Yosa is committed to protecting the privacy and security of your health information.
  </p>

  <ul>
    <li>Encryption: All PHI you provide is encrypted during transmission and while stored on our systems, ensuring that your data is protected against unauthorized access.</li>
    <li>Access Controls: Access to sensitive information is strictly controlled. Each user must have a unique username and password, and roles are defined based on job functions to ensure that individuals only have access to the information necessary for their duties.</li>
    <li>Multi-factor Authentication (MFA): MFA is enforced on all critical systems, including our cloud services and application interfaces.</li>
    <li>Risk Assessments: Yosa conducts and documents risk assessments to identify and address potential vulnerabilities to the confidentiality, integrity, and availability of PHI.</li>
    <li>Employee Training and Awareness: All employees undergo security training to ensure they understand and can effectively implement our security policies. Training is conducted annually.</li>
    <li>Audit Trails: We maintain detailed logs of all access to and interactions with PHI. These audit trails help us monitor and manage access, ensuring compliance with our security policies and procedures.</li>
    <li>Third-Party Safeguards: We enter into Business Associate Agreements (BAAs) with all third-party service providers who may handle or have access to PHI, ensuring they meet our strict privacy and security standards. This includes major providers like AWS for cloud hosting.</li>
    <li>Network and Application Security: We implement technical safeguards such as automatic sign-out after periods of inactivity and ongoing monitoring of network traffic to prevent unauthorized access.</li>
    <li>Data Protection and Loss Prevention: Advanced tools like AWS Macie are utilized to automatically scan and classify sensitive data, monitor for anomalous activities, and provide alerts on potential data breaches.</li>
  </ul>
  <p className="policy-header">I. NO WAIVER</p>
  <p>
    Under no circumstances will Yosa require an individual to waive his or her rights under the HIPAA Privacy Rule or the HIPAA Breach Notification Rule as a condition for receiving treatment.
  </p>

  <p className="policy-header">J. CONTACT/COMPLAINT INFORMATION</p>
  <p>
    If you have any questions about this Notice or wish to file a privacy complaint, please contact:
  </p>
  
  <p>
    Privacy Officer
    <br />
    (508) 369-9939
    <br />
    or email support@yosaapp.com
  </p>
  
  <p>
    You can file a complaint directly with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to:
  </p>
  
  <p>
    200 Independence Avenue, S.W
    <br />
    Washington, D.C. 20201
    <br />
    calling 1-877-696-6775
    <br />
    or online at: <a href="http://www.hhs.gov/ocr/privacy/hipaa/complaints/" target="_blank" rel="noopener noreferrer">www.hhs.gov/ocr/privacy/hipaa/complaints/</a>
  </p>
  
  <p>
    We may not retaliate against you for filing a complaint.
  </p>

  <p>
    Yosa Notice of Privacy Practices Effective August 9, 2024
  </p>
  </div>
</main>
            </body>
            </html>
            <footer className="footer">
  <div className="footer-content">
    <p>&copy; 2024 Yosa Inc. All rights reserved.</p>
    <nav>
      <ul>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
        <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
        <li><a href="/terms-of-service">Terms of Service</a></li>
        <li><a href="/support">Support</a></li>
      </ul>
    </nav>
    <div className="footer-bottom">
      <p>
        <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
        <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
        </a>
      </p>
    </div>
    <div className="footer-bottom">
      <form id="waitlist-email-form" onSubmit={handleSubmit} className="email-form">
        <div className="email-form-group">
          <label htmlFor="email" className="waitlist-label footer-text">Enter your email to join our waitlist!</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter email here"
            value={email}
            onChange={handleEmailChange}
            className="email-input"
          />
          <button type="submit" className="waitlist-email-form-button">Submit</button>
        </div>
      </form>
    </div>
  </div>
</footer>
        </>
    )
}

export default NoticeOfPrivacyPractices;