import React from 'react';
import logo from './logo.svg';
import './styles/Home.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/landingpage/Home';
import DashboardApp from './components/providerdashboard/DashboardApp';
import SignUp from './components/signup';
import ConfirmSignUp from './components/confirmsignup';
import Dashboard from './components/dashboard';
import LogIn from './components/login';
import PrivacyPolicy from './components/privacypolicy';
import ForgotPassword from './components/forgotpassword';
import ConfirmResetPassword from './components/confirmresetpassword';
import TermsOfService from './components/termsofservice';
import NoticeOfPrivacyPractices from './components/noticeofprivacypractices';
import Support from './components/support';
import Amplify from '@aws-amplify/core';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/providerdash' element={<DashboardApp />}></Route>
        <Route path='/signup' element={<SignUp />}></Route>
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/login' element={<LogIn />}></Route>
        <Route path='/confirm-sign-up' element={<ConfirmSignUp />}></Route>
        <Route path='/forgotpassword' element={<ForgotPassword />}></Route>
        <Route path='/confirmresetpassword' element={<ConfirmResetPassword />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/notice-of-privacy-practices" element={<NoticeOfPrivacyPractices />}></Route>
        <Route path="/support" element={<Support />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;