import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import yosaLogo from '../images/yosalogo.png';
import signUpPic from '../images/signup.png';
import { resetPassword } from 'aws-amplify/auth';
import '../styles/SignUp.css';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    async function handleResetPassword(username) {
        try {
            const output = await resetPassword({ username });
            handleResetPasswordNextSteps(output, username);
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message || 'An error occurred during reset password.');
        }
    };

    function handleResetPasswordNextSteps(output, email) {
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                console.log(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
                goToConfirmResetPassword(email);
                break;
            case 'DONE':
                console.log('Successfully reset password.');
                break;
            default:
                console.error('Unknown step:', nextStep);
                setErrorMessage('An unknown error occurred.');
                break;
        }
    }

    const onFinish = (values) => {
        console.log('Forgot Password Form Values:', values);
        handleResetPassword(values.email);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Forgot Password Form Failed:', errorInfo);
        setErrorMessage('Please fill in all required fields.');
    };

    const goToLogin = () => {
        navigate('/login');
    };

    const goToConfirmResetPassword = (email) => {
        navigate('/confirmresetpassword', { state: { email } });
    }

    return (
        <>
            <section className='signup-container'>
                <div className='signup-left'>
                    <img id='signupimage' src={signUpPic} alt='Yosa Lady' />
                </div>
                <div className='signup-right'>
                    <img className='signuplogo' src={yosaLogo} alt="Yosa Logo" />
                    <Form
                        name="forgot-password-form"
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {errorMessage && (
                            <div className='error-message'>
                                <p style={{ color: 'red' }}>{errorMessage}</p>
                            </div>
                        )}

                        <div className='center'>
                            <Button className='forgot-password' onClick={goToLogin}>Remembered your password? Log in here</Button>
                            <Form.Item>
                                <Button className='submit-button' type="primary" htmlType="submit">
                                    Reset Password
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </section>
        </>
    );
};

export default ForgotPassword;
