import React, { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../styles/SignUp.css';
import yosaLogo from '../images/yosalogo_color_changed.png';
import linkedinicon from '../images/linkedinicon.png';

const Support = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const goToSignUp = () => {
        navigate('/signup');
    };

    const goToLogIn = () => {
        navigate('/login');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            // Add your email submission logic here
        } catch (error) {
            // Handle submission error here
        }
    };

    return (
        <>
            <html>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Yosa Support Page</title>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
                </style>
            </head>
            <body>
                <header className="policyheader">
                    <a href="/">
                        <img src={yosaLogo} alt="Yosa Logo" />
                    </a>
                    <nav>
                        <ul>
                            <li><Button className='login-button' onClick={goToSignUp}>Sign Up</Button></li>
                            <li><Button className='login-button' onClick={goToLogIn}>Log In</Button></li>
                        </ul>
                    </nav>
                </header>
                <main>
                    <h1 className="privacy-title">Support</h1>
                    <p className="last-updated">Last Updated: August 6, 2024</p>
                    <div className="content-container">
                        <p>Welcome to the Yosa Support page. Here you can find answers to frequently asked questions and contact our support team for further assistance.</p>
                        
                        <p className="policy-header">1. Frequently Asked Questions</p>
                        <p><strong>Q: How do I reset my password?</strong></p>
                        <p>A: To reset your password, click on the "Forgot Password" link on the login page and follow the instructions.</p>
                        
                        <p><strong>Q: How do I update my account information?</strong></p>
                        <p>A: You can update your account information by logging into your account and navigating to the account settings page.</p>
                        
                        <p><strong>Q: How do I contact support?</strong></p>
                        <p>A: You can contact our support team by emailing us at <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.</p>
                    </div>
                </main>
                <footer className="footer">
                    <div className="footer-content">
                        <p>&copy; 2024 Yosa Inc. All rights reserved.</p>
                        <nav>
                            <ul>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                                <li><a href="/terms-of-service">Terms of Service</a></li>
                                <li><a href="/support">Support</a></li>
                            </ul>
                        </nav>
                        <div className="footer-bottom">
                            <p>
                                <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                                <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                                </a>
                            </p>
                        </div>
                        <div className="footer-bottom">
                            <form id="waitlist-email-form" onSubmit={handleSubmit} className="email-form">
                                <div className="email-form-group">
                                    <label htmlFor="email" className="waitlist-label footer-text">Enter your email to join our waitlist!</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Enter email here"
                                        value={email}
                                        onChange={handleEmailChange}
                                        className="email-input"
                                    />
                                    <button type="submit" className="waitlist-email-form-button">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </footer>
            </body>
            </html>
        </>
    );
};

export default Support;